a {
  text-decoration: none !important;
}
.sign-phone {
  padding-left: 111px !important;
}
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none !important;
  position: absolute !important;
  z-index: 1 !important;
  font-size: 13px !important;
  left: 25px !important;
  top: -7px !important;
  background: #fff !important;
  padding: 0 5px !important;
  white-space: nowrap !important;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: none !important;

  /* margin-top: 10px !important;
    padding-top: 0px !important; */
  /* padding: 18.5px 9px 18.5px 9px !important; */
}
.react-tel-input .country-list {
  width: 250px !important;
}

.react-tel-input {
  position: absolute !important;
  width: 25% !important;
  left: 2px;
  top: 4px;
}
.relative {
  position: relative;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border: none !important;
}
.css-13cymwt-control {
  border-radius: 0.75rem !important;
  border: 1px solid #f0f1f5 !important;
}
/* Replace these colors with your preferred values */
.react-google-places-autocomplete__input::placeholder {
  color: gray !important; /* Change the placeholder color */
}

.suggestions {
  margin-top: 5px;
  padding: 4px;
  border: 1px solid gray;
  border-radius: 0.75rem;
  margin-bottom: 10px;
}
.suggestion {
  padding: 5px 10px;
  border-bottom: 1px solid gray;

  cursor: pointer;
}

.suggestion:hover {
  background-color: #f0f0f0;
}
.suggestion:last-of-type {
  border-bottom: none;
}
@media screen and (max-width: 1200px) {
  .react-tel-input .form-control {
    padding: 12px 9px 13px 60px !important;
  }
}

.chat-input-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 10px;
}

.file-upload-label {
  cursor: pointer;
}

.upload-icon {
  font-size: 1.2rem;
  color: #6c757d;
}

.upload-icon:hover {
  color: #0d6efd;
}
