.mobile-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.left-modal-dialog {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  width: 30% !important;
  max-width: none !important;
}

.left-modal .modal-content {
  border-radius: 0 !important;
}

@media (max-width: 500px) {
  .mobile-placeholder {
    display: none;
  }
  .chat-page > :nth-child(1):not(:empty),
  .chat-page > :nth-child(2):not(:empty) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    margin: auto;
  }
  .chat-page {
    display: block !important;
    margin-top: 10px;
    height: calc(100vh - 100px) !important;
  }
  .message-base {
    max-width: 200px !important;
  }
  .model-chat-box > :nth-child(1):not(:empty),
  .model-chat-box > :nth-child(2):not(:empty) {
    align-items: normal;
    width: 340px;
    margin: auto;
  }
  .model-chat-box {
    height: calc(100vh - 120px) !important;
  }
  .left-modal-dialog {
    width: 80% !important;
  }
}

.tabs-container {
  display: flex;
  flex-direction: column;
}

.conversation-column {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px); /* Increased space for the button */
  overflow-y: auto;
  padding-bottom: 20px; /* Add some padding at the bottom */
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.message-date-separator {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.date-line {
  position: relative;
  z-index: 1;
}

.date-line::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #e0e0e0;
  z-index: -1;
}

.date-text {
  background: rgb(231, 231, 231);
  border-radius: 10px;
  padding: 2px 10px;
  color: #000000;
  font-size: 0.9rem;
}

/* Tab styling */
.tabs-container .nav-tabs {
  border-bottom: none;
  gap: 8px;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}

.tabs-container .nav-tabs .nav-link {
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  color: #495057;
  font-weight: 500;
  background-color: transparent;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.tabs-container .nav-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #212529;
}

.tabs-container .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #212529;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Add specific styling for the load more button if needed */
.load-more-button {
  margin: 10px 0;
  z-index: 1;
  position: relative;
}


.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.active {
  background-color: #4caf50;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: left 0.3s;
}

.toggle-switch.active .toggle-circle {
  left: 25px; /* Move circle to the right */
}

